// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

import '../styles/admins/vendor/js/jquery/jquery-2.0.3.min.js'
import 'bootstrap/dist/js/bootstrap.js'
// import '../styles/admins/vendor/bootstrap/js/bootstrap.min.js'
import '../styles/admins/vendor/js/jQuery-Cookie/jquery.cookie.js'
import '../vendor/slick/slick.js'

import '../styles/admins/vendor/font-awesome/css/font-awesome.min.css'
import 'bootstrap/dist/css/bootstrap.css'
// import '../styles/admins/vendor/bootstrap-4.4.1/dist/css/bootstrap.css'
import '../vendor/slick/slick.css'
import '../vendor/slick/slick-theme.css'
import '../styles/application'

$(function () {
	$('.slick').slick();

	$(document).on("click", "#btn_add_cart", function() {
		var checked_list = $("[name='product[student][]']:checked").map(function() { return $(this).val(); }).get();
		
		if (checked_list.length > 0) {
			var size = $("[name='product[size]']").val(),
					product = $("[name='product[id]']").val(),
					url = $("#form_product").prop("action"),
					data = {product: product, size: size, students: checked_list};
			$.ajax({
				url: url,
				method: "post",
				data: data,
				success: function (response) {
					if (response.success) {
						// alert("加入购物车成功");
					} else {
						// alert("加入购物车失败");
					}
				}
			});
		} else {
			// alert("请选择学生");
		}
		$("[name='product[student][]']").each(function(i) {$(this).prop("checked", false)});
	});

	// $(document).on("click", ".btn-size-table", function () {
	// 	var url = $(this).data("url");
	// 	console.log(123);

	// 	$.ajax({
	// 		url: url,
	// 		success: function (response) {
	// 			if (response.success) {
	// 				$("#size_table").html(response.data);
	// 				$("#cover_size_table").show();
	// 			}
	// 		}
	// 	});
	// });

	$(document).on("click", ".btn-size-introduce", function () {
		$("#cover_size_introduce").show();

		if ($("video").length > 0) {
			$("video")[0].muted = false;
			$("video")[0].play();
		}
	});

	$(document).on("click", "#cover_size_introduce .btn-close, #cover_size_table .btn-close, .cover_size_table .btn-close", function () {
		$(".cover-container").hide();

		if ($("video").length > 0) {
			$("video")[0].pause();
			$("video")[0].currentTime = 0;
		}
	});

	if ($("#signature-pad-canvas").length > 0) {
		const canvas = $("#signature-pad-canvas")[0];
	  const hidden_field = $("#signature-pad-canvas-hidden")[0];

	  if (canvas && hidden_field) {
	    const parent_form = canvas.closest("form");
	    const signaturePad = new SignaturePad(canvas);

	    parent_form.onsubmit = function() {
	    	if (signaturePad.isEmpty()) {
	    		alert("簽名不可为空");
	    		return false;
	    	}
	      hidden_field.value = signaturePad.toDataURL()
	    }

	    function resizeCanvas() {
	      var ratio =  Math.max(window.devicePixelRatio || 1, 1);
	      canvas.width = canvas.offsetWidth * ratio;
	      canvas.height = canvas.offsetHeight * ratio;
	      canvas.getContext("2d").scale(ratio, ratio);
	      // signaturePad.clear();
	      console.log("resize");
	    }

	    // window.addEventListener("resize", resizeCanvas, true);
	    resizeCanvas();

	    $(document).on("click", ".btn-resign", function () {
        signaturePad.clear();
      });
	  }
	}
});